<template lang="pug">
div
  van-tabs(v-model="active" sticky offset-top='46'  style='margin-bottom:50px;')
    van-tab(title='基本信息')
      //- van-tab(v-for="(group, gindex) of groups" 
      //-   :title="group.name"
      //-   :key='gindex'
      //-  ) 
      van-cell-group(v-for="(group, gindex) of groups" 
        :title="group.name"
        :key='gindex'
      )   
        component(:is="group.template", v-for="(child, index) of group.children" :key='index' :value='groups_data[group.key][child.key]' :label='child.label' readonly)
    van-tab(title='订单进度')
      van-steps(direction="vertical" :active="activeStep")
        van-step(v-for='(step,index) in normalSteps' :key='index')
          p {{step.text}}
          p {{step.time}}
  van-submit-bar(v-if='origin && (origin.order_state == "wait_send" || origin.order_state == "wait_get_goods" || (logistics.length > 0)) ' button-text='查看物流' @submit='step=true')
  //- van-submit-bar(v-if='origin' button-text='查看物流' @submit='step=true ')
  //- van-action-sheet(style='height:100%' v-if='step' v-model='step', title='发货记录')
    logistics-view(:id='this.id')
  loading-view(:value="loadingState")
</template>

<script>
// import LogisticsView from '../../components/other/LogisticsView'
import { mapGetters } from 'vuex'

export default {
  components: {
    // [LogisticsView.name]: LogisticsView
  },
  data() {
    return {
      loadingState: false,
      active: 0,
      activeStep: -1,
      steps: [],
      normalSteps: [], // 应该显示出来的进度
      step_times: {},
      groups: [
        { 
          key: 'order_data',
          name: '订单信息',
          template: 'van-field',
          children: [
            { key: 'code', label: '订单编号'},
            // { key: 'title', label: '订单名称'},
            { key: 'cycle_time', label: '下单日期'},
            // { key: 'order_rel_contract', label: '合同状态'},
            { key: 'order_state', label: '订单状态'},
            { key: 'remark', label: '订单备注'},
          ]
        },
        { 
          key: 'store_data',
          name: '销售信息',
          template: 'van-field',
          children: [
            // { key: 'st_name', label: '所属门店'},
            // { key: 'id', label: '销售员ID'},
            { key: 'name', label: '销售员姓名'},
            { key: 'tel', label: '销售员电话'},
          ]
        },
        { 
          key: 'menu_data',
          name: '客户信息',
          template: 'van-field',
          children: [
            { key: 'company_name', label: '客户名称'},
            { key: 'receipt', label: '收货人姓名'},
            { key: 'address', label: '收货人地址'},
            { key: 'tel', label: '收货人电话'},
            // { key: 'postcode', label: '收货人邮编'},
            // { key: 'receipt_remark', label: '收货人备注'},
          ]
        },
        { 
          key: 'goods_data',
          name: '货品明细',
          template: 'goods-list',
          children: [
            { key: 'list', },
          ]
        },
        { 
          key: 'total',
          name: '总计',
          template: 'van-field',
          children: [
            { key: 'count', label: '产品数量'},
            { key: 'factory_total_price', label: '产品金额'},
            { key: 'factory_favor_amount', label: '订单优惠'},
            { key: 'cost', label: '运输安装费'},
            { key: 'packaging_price', label: '包装费用'},
            { key: 'factory_discount_price', label: '订单合计'},
          ]
        },
      ],
      groups_data: {
        store_data: {
          // st_name: '',
          // id: '',
          name: '',
          tel: '', 
        },
        order_data: {
          code: '',
          title: '',
          cycle_time: 0,
          order_rel_contract: '',
          order_state: '',
          remark: '',
        },
        menu_data: {
          company_name: '',
          receipt: '',
          address: '',
          tel: '', 
          postcode: '',
          receipt_remark: '',
        },
        goods_data: {
          list: []
        },
        total: {
          count: 0,
          price: 0,
        }
      },
      id: 0,
      step: false,
      origin: null,
      logistics: [] // 物流信息
    }
  },
  computed: {
    ...mapGetters(['getFlow']),
    // activeStep() {
    //   let result = -1
    //   result = this.steps.indexOf(p=> p.value == this.groups_data.order_data.order_state)
    //   return result
    // }
  },

  methods: {
    formatPrice(price) {
      return (price/1).toFixed(2);
    },
    async getOrderDetail() {
      try{
        let condition = {
          where: {
            id: this.id
          },
          include: [
            {model: 'customer'},
            {model: 'orderItem'},
            {model: 'seller'},
            {model: 'contract'},
            // {model: 'logistics'},
          ]
        }
        this.loadingState = true
        let res = await this.$api.GetOrderNew(condition)
        const msg = res.data
        const { code, title, order_state, remark, cycle_time, pre_ofc_cus_company, pre_contracts, pre_ofc_order_items, pre_sell_user } = msg.items[0]
        const orderFlow = (await this.getFlow('order')).status

        this.groups_data.order_data = {
          code, 
          title, 
          remark,
          cycle_time:this.dataFormat(cycle_time), 
          order_rel_contract: pre_contracts ? '已上传':'未上传',
          order_state: (order_state && order_state in orderFlow) ? orderFlow[order_state].alias : ''
        }
        this.groups_data.goods_data.list = pre_ofc_order_items

        if (pre_sell_user) {
          this.groups_data.store_data = {
            name: pre_sell_user.name,
            tel: pre_sell_user.tel
          }
        }
        const {company_name, receipt, address, tel, postcode, receipt_remark, ...rest} =  msg.items[0]
        this.groups_data.menu_data = {company_name, receipt, address, tel, postcode, receipt_remark}

        if (pre_ofc_cus_company) {
          this.groups_data.menu_data.company_name = pre_ofc_cus_company.fullname
        }
        this.origin = msg
        let count = 0
        let pic = 0
        pre_ofc_order_items.forEach(({num, factory_price})=> {
          count += parseInt(num)
          pic += parseFloat(factory_price) * parseInt(num)  
        })
        pic = pic.toFixed(2)
        this.groups_data.total = {
          count: `${count}  件`,
          factory_total_price: `￥${parseFloat(rest.factory_total_price)}`,
          factory_favor_amount: `￥${parseFloat(rest.factory_favor_amount)}`,
          cost: `￥${parseFloat(rest.cost)}`,
          packaging_price: `￥${parseFloat(rest.packaging_price)}`,
          factory_discount_price: `￥${parseFloat(rest.factory_discount_price)}`,
        }
        await this.setSteps()
        const stepIndex = this.steps.findIndex(p=> p.value == order_state)
        this.activeStep = stepIndex
        await this.getLog()
        const parma = {
          where: {
            order_poi_ofc_order: this.id
          },
          keys: 'id'
        }
        // const logistics =  await this.$api.GetLogisticsNew(parma)
        // this.logistics = logistics.data.items
        this.setNormalSteps()
      }catch(ex){
        this.$toast(this.$error(ex))
      }
      this.loadingState = false
    },

    // 获取订单的历史记录
    async getLog() {
      let params = {
        where: {
          handle_id: this.id,
          flow_poi_flow: 2,
          uid_poi_users: 0,
          st_id_poi_company_stores: 0
        }
      }
      let msg = await this.$api.GetFlowLog(params)
      msg.data.items.forEach((sub)=> {
        let step = this.steps.find(p=> p.value == sub.flow_state)
        if (step) {
          step.time = sub.created_at
          this.$set(step, 'time', sub.created_at)
        }
      })
    },

    async setSteps() {
      let res = await this.getFlow('order')
      this.flowStatus = res.status
      if (res.status) {
        let keys = Object.keys(res.status)
        let status = keys.map((item,index) => {
          return { 
            value: item,
            text: res.status[item].alias
          }
        })
        this.steps = status
      }
    },

    setNormalSteps() {
      let steps = this.steps
      if(this.origin.order_state == 'canceled' || this.origin.order_state == 'termination' || this.origin.order_state == 'cancel_order') {
        steps = this.steps.filter(p => p.time)
      } else {
        steps = this.steps.filter(p => p.value != 'canceled' && p.value != 'termination' && p.value != 'cancel_order')
      }
      this.normalSteps = steps
    }
  },
  async mounted() {
    this.id = this.$route.query.id
    await this.getOrderDetail()
    this.$emit('on-nav', {
      leftText: '返回',
      leftArrow: true,
      onClickLeft: () => {
        this.$router.go(-1)
      },
      rightText: '主页',
      rightArrow: true,
      onClickRight: () => {
        this.$router.push({name: 'home'})
      }
    })
  },
}
</script>
